<template>
    <div class="page">
        <div class="page-header pd">
            <sc-button icon="chevron-left" @click="$router.push(`/app/${resource.route}/index`)">Back</sc-button>
        </div>

        <div class="pd">
            <sc-form :form="form" @submit="submit()" v-if="form">

                <template #field-template_id>
                    <sc-form-field :form="form" field="template_id" name="System Template*">
                        <div class="system-templates">
                            <div class="template" v-for="t in systemTemplates" @click="selectSystemTemplate(t)"
                                 :class="{selected: currentTemplate && currentTemplate.id === t.id}">
                                <div class="name">{{ t.attributes.name }}</div>
                                <img :src="getThumb(t)" class="thumb"/>
                            </div>
                        </div>
                    </sc-form-field>
                </template>

                <template #field-fields>
                    <div v-if="!currentTemplate || currentTemplate.attributes.fields === null" style="display:none;"/>

                    <sc-form-field v-else :form="form" field="fields" name="Template Fields*">
                        <template v-for="(field,i) in form.fields.fields">
                            <sc-form-field :key="`fields-${currentTemplate.id}-${i}`" :name="field.name">
                                <input :type="currentTemplate.attributes.fields[i].type === 'integer' ? 'number' : 'text'" v-model="field.value"/>
                            </sc-form-field>
                        </template>
                    </sc-form-field>
                </template>

            </sc-form>
        </div>
    </div>

</template>

<script>
export default {
  name: "new",

  props: {
    passed: {
      required: true,
    }
  },

  data() {
    return {
      form: null,
      systemTemplates: [],
    }
  },

  computed: {
    resource() {
      return this.passed
    },

    currentTemplate() {
      if (this.systemTemplates.length > 0) {
        return this.systemTemplates.find((t) => t.id === this.form.fields.template_id);
      }
    }
  },

  mounted() {
    this.form = this.$sform.createForm({
      name: `New ${this.resource.name}`,
      url: this.resource.api,
      fields: {
        template_id: {},
        name: {
          name: 'Name*'
        },
        description: {},
        fields: {
          default: []
        },
      },
      success: () => {
        this.$notify.success(`${this.resource.name} added`)
        this.$router.push(`/app/${this.resource.route}/index`)
      }
    })

    this.$talker.api('system-templates?category=ois')
      .then((res) => {
        this.systemTemplates = res.data.data;
      })
  },

  methods: {
    submit() {
      if (this.form.fields.fields.length > 0) {
        for (let i = 0; i < this.form.fields.fields.length; i++) {
          let f = this.form.fields.fields[i];
          if ([undefined,''].includes(f.value)) {
            this.form.errors.record({"fields": ["The fields field is required."]});
            return
          }
        }
      }

      this.form.post();
    },

    getThumb(template) {
      return process.env.VUE_APP_AWS_URL + template.attributes.thumb;
    },

    selectSystemTemplate(template) {
      this.form.fields.template_id = template.id;
      this.form.fields.fields = [];

      if (this.currentTemplate.attributes.fields) {
        this.currentTemplate.attributes.fields.forEach((f) => {
          this.form.fields.fields.push({
            "name": f.name,
            "value": f.value
          })
        })
      } else {
        this.form.fields.fields = undefined;
      }
    }
  }
}
</script>

<style scoped lang="scss">

.system-templates {
    display: flex;
    flex-flow: wrap;

    .template {
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        margin: .5em;
        cursor: pointer;
        padding: 0;

        .name {
            margin: .25em 0;
            font-size: .8em;
            font-weight: bold;
        }

        .thumb {
            max-widtH: 400px;
            object-fit: contain;
            opacity: .5;
            transition: opacity $easing $transition-duration;
            margin-bottom: 0;
        }

        &:hover {
            .thumb {
                opacity: .75;
            }
        }

        &.selected {
            .thumb {
                opacity: 1;
            }
        }
    }
}

</style>